function NoMatch() {
  return (
    <div>
      <h1>
        Sorry, this page doesn't exist. Please check the URL or go back a page.
      </h1>
      <h2>404 Error: Page Not Found</h2>
    </div>
  );
}

export default NoMatch;
